import { Fragment, useRef, useState } from "react";
import styled from "styled-components";
import { toPng } from "html-to-image";
import download from "downloadjs";
import mpdImage from "./assets/mpd-ad-template-2.png";
import ddlImage from "./assets/ddl-ad-template.png";
import generateMPDAdBody from "./data/mpd-template";
import generateDDLAdBody from "./data/ddl-template";

const Nav = styled.nav`
  display: flex;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const NavItem = styled.div`
  width: 100%;
  font-size: 32px;
  color: #f2f2f2;
  font-weight: 500;
  text-align: center;
  margin: 0px 10px;
  background-color: skyblue;
  border-radius: 3px;

  ${(props) =>
    props.isActive &&
    `
    cursor: default;
    background-color: rebeccapurple;
    pointer-events: none;
    `}

  ${(props) =>
    !props.isActive &&
    `
    cursor: pointer;

    &:hover {
      background-color: rebeccapurple;
    }

  `}
`;

const Content = styled.div`
  width: 100%;
  padding: 10px;

  & > div {
    padding: 10px;
    border: 1px solid steelblue;
    margin: 0;
  }
`;

const ExportWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const ExportableImageContainer = styled.div`
  position: relative;
  width: 1200px;
  height: 900px;
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const CTAPhoneNumber = styled.div`
  position: absolute;
  z-index: 10;

  ${(props) => props.customStyles || `display: none;`}
`;

const ProfilePhoneNumber = styled.div`
  position: absolute;
  z-index: 10;

  ${(props) => props.customStyles}
`;

const InputContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const Button = styled.button`
  display: block;
  margin: 15px auto;
  width: 150px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
`;

const IsCopied = styled.div`
  text-align: center;
  font-weight: bold;
  color: #20c997;
  font-size: 22px;
`;

const FEATURES = {
  FEATURED_IMAGE: "Featured Image",
  AD_BODY: "Ad Body",
};

const PROFILES = {
  MATT_PECK_DEVELOPMENT: "Matt Peck Development",
  DOXA_DESIGN_LAB: "Doxa Design Lab",
};

const SETTINGS = {
  [PROFILES.MATT_PECK_DEVELOPMENT]: {
    ctaNumber: `
      bottom: 88px;
      left: 340px;
      letter-spacing: 1.7px;
      font-weight: 500;
      font-size: 44px;
      color: #f2f2f2;
  `,
    profileNumber: `
      top: 275px;
      right: 124px;
      color: #3f3d56;
      font-size: 18px;
      font-weight: 500;
    `,
    image: mpdImage,
    getAdBody: generateMPDAdBody,
  },
  [PROFILES.DOXA_DESIGN_LAB]: {
    cta: null,
    profileNumber: `
      top: 124px;
      left: 258px;
      color: #333333;
      letter-spacing: -0.1px;
      font-size: 19px;
      font-weight: 600;
    `,
    image: ddlImage,
    getAdBody: generateDDLAdBody,
  },
};

const App = () => {
  const imageRef = useRef();
  const contentRef = useRef();
  const [activeProfile, setActiveProfile] = useState(
    PROFILES.MATT_PECK_DEVELOPMENT
  );
  const [phoneNumber, setPhoneNumber] = useState("(540) 867-5309");
  const [context, setContext] = useState("Fredericksburg");
  const [activeFeature, setActiveFeature] = useState(FEATURES.FEATURED_IMAGE);
  const [isCopied, setIsCopied] = useState(false);

  const settings = SETTINGS[activeProfile];

  const handleProfileSelect = (event) => {
    setActiveProfile(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleExport = async () => {
    const dataUrl = await toPng(imageRef.current, {
      canvasWidth: 2400,
      canvasHeight: 1800,
    });
    download(dataUrl, "ad-template.png");
  };

  const handleCopy = () => {
    const copyText = settings.getAdBody(context, phoneNumber);
    navigator.clipboard.writeText(copyText);
    setIsCopied(true);
  };

  const handleNavigation = (feature) => () => {
    setActiveFeature(feature);
  };

  return (
    <Fragment>
      <Nav>
        <NavItem
          isActive={activeFeature === FEATURES.FEATURED_IMAGE}
          disabled={activeFeature === FEATURES.FEATURED_IMAGE}
          onClick={handleNavigation(FEATURES.FEATURED_IMAGE)}
        >
          Featured Image
        </NavItem>
        <NavItem
          isActive={activeFeature === FEATURES.AD_BODY}
          disabled={activeFeature === FEATURES.AD_BODY}
          onClick={handleNavigation(FEATURES.AD_BODY)}
        >
          Ad Body
        </NavItem>
      </Nav>
      <InputContainer>
        <label>Profile: </label>
        <select value={activeProfile} onChange={handleProfileSelect}>
          {Object.keys(PROFILES).map((p) => {
            return (
              <option key={p} value={PROFILES[p]}>
                {PROFILES[p]}
              </option>
            );
          })}
        </select>
      </InputContainer>
      <InputContainer>
        <label>Phone Number: </label>
        <input
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </InputContainer>
      {activeFeature === FEATURES.FEATURED_IMAGE && (
        <Fragment>
          <Button onClick={handleExport}>Export</Button>
          <ExportWrapper>
            <ExportableImageContainer ref={imageRef}>
              <Image src={settings.image} alt="feature ad" />
              <CTAPhoneNumber customStyles={settings.ctaNumber}>
                {phoneNumber}
              </CTAPhoneNumber>
              <ProfilePhoneNumber customStyles={settings.profileNumber}>
                {phoneNumber}
              </ProfilePhoneNumber>
            </ExportableImageContainer>
          </ExportWrapper>
        </Fragment>
      )}
      {activeFeature === FEATURES.AD_BODY && (
        <Fragment>
          <InputContainer>
            <label>Context Greeting: </label>
            <input type="text" value={context} onChange={handleContextChange} />
          </InputContainer>
          <Button onClick={handleCopy}>Copy</Button>
          {isCopied && <IsCopied>Copied!</IsCopied>}
          <Content
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: settings.getAdBody(context, phoneNumber),
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default App;
