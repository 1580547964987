const generateAdBody = (context, phoneNumber) => `<div>
Hello ${context},<br /><br />

Are you needing help with your web app?<br /><br />

I'm Matt, and I've been developing web apps for more than 11 years. Let me use the skills I’ve been sharpening for over a decade to help you build your app!<br /><br />

<h3>High Quality React Development:</h3>
 ✔️  Get reusable React components that match your User Interface Design<br />
 ✔️  Get React Hooks that abstract common logic and keep code readable and easy to debug<br />
 ✔️  Get best practice open source software React package integrations<br />
 ✔️  Get your own sandbox for experimenting with the components created<br />
 ✔️  Get Senior level debugging skills for finding and squashing bugs in existing codebase<br />
 ✔️  Get clean and helpful code commenting that helps your team understand how it works<br />
 ✔️  Stop spending hours and hours trying to make bad code work in your app<br />
 ✔️  Stop wasting time dealing with offshore hours, slow communication, and delayed work expectations<br />
 <br /><br />

<h3>Full Stack Skills:</h3>
 ☆  React / Redux / React Hooks<br />
 ☆  Create React App / NextJS<br />
 ☆  HTML / CSS / JS (ES6+)<br />
 ☆  Node.js / Express.js<br />
 ☆  MongoDB / PostgreSQL<br />
 ☆  AWS -- API Gateway / Lambda / RDS / S3 / CodePipeline<br />
 ☆  Firebase / Netlify
<br /><br />

100% Happy Clients? Absolutely! Take a look:
<br /><br />

<h3>Recent Review:</h3>
★★★★★<br />
<i>“Matt gets the design and development relationship that makes for the best product output. He also has a natural sense of leadership and can step up when needed or dig into the details.”</i><br /><br />

Bill & Andrea Moschella, Co-Owners<br />
Masheen
<br /><br />

<h3>Just imagine how happy you’ll be with your site too!</h3>
 ✔️  100% Guaranteed you’ll be happy!<br />
 ✔️  I care about my clients and I’m looking forward to hearing about your unique project!<br />
 ✔️  You can actually get a hold of me by phone (Try it, I’m currently available!)
<br /><br />

Let's get in touch to discuss your project's needs: ${phoneNumber}<br /><br />
Sincerely,<br /><br />

Matt Peck<br /><br /><br />

☎️  Call: ${phoneNumber}<br />
🕒  Available 9am - 5pm, All Week<br />
📩  matt@mattpeckdevelopment.com<br />
💻  mattpeckdevelopment.com<br />
📍 linkedin.com/in/matt-peck-dev/<br /><br />

<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
Tags: React Developer | Redux | Firebase | AWS | Amazon Web Services | ES6 | JavaScript | Javascript | Node.js | NodeJS | NodeJs | Nodejs | Express.js | Node | Express | ExpressJS | ExpressJs | Expressjs | API Development | App Development | Application Development | Web App Development | Web Application Development | Web Developer | Front End Developer | Frontend Developer | UI | UX | UI/UX | User Interface | User Experience | Front End Engineer | Frontend Engineer | Back End Developer | Backend Developer | Backend Engineer | Back End Engineer | Fullstack Developer | Fullstack Engineer | Full Stack Developer | Full Stack Engineer | Bugfixes | Bug Fix | Html | HTML | HTML5 | Html5 | Freelancer | Ecommerce | Business | Web | Business Web Development | Architect | Web Repair | Big Commerce | E-commerce | Computers | Architects | Application | Architecture | Developer | Development <br /><br /><br />


<h3>☏ Call Now ${phoneNumber} to chat about your project! ☏</h3><br /><br />
</div>`;

export default generateAdBody;
